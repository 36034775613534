import * as React from "react"
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import { faAngleDown, faAngleUp, faChartArea, faFlagUsa, faFolderOpen, faGlobeEurope, faPaperclip, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faAngular, faBootstrap, faReact, faVuejs, faReadme } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export default ({ cover, avatar, title, text, subtitle, topContent, onButtonClick, buttonText }) => {
  return <Card border="light" className="text-center p-0 mb-4">
    {
      !topContent && <div style={{ backgroundImage: `url(${cover})` }} className="profile-cover rounded-top" /> 
      }
    <Card.Body className="pt-0 pb-5 px-0">
      {
        topContent
      }
      {
        avatar && <Card.Img src={avatar} alt="Neil Portrait" className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" />
      }


      <Card.Text style={{ height: '60px', marginTop: "8px"}} className="text-gray">{text}</Card.Text>

      <Button variant="primary" size="sm" className="me-2"  onClick={() => onButtonClick && onButtonClick()}>
         { buttonText }
      </Button>
      {
        "" && <Button variant="secondary" size="sm">Send Message</Button>
      }
    </Card.Body>
  </Card>
}