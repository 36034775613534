import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'
import { Container, Row, Col, Nav } from '@themesberg/react-bootstrap';
import * as cookie from 'cookie'
import { navigate } from "gatsby"
import Parse from '../vendor/Parse'
import Cookies from 'js-cookie'
const { useEffect, useState } = React;
const Profile1 = "images/2021-01-30.jpg";

const iframeStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%'
}

const iframeContainerStyle = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  paddingTop: '56.25%', /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

const IndexPage = ({ pageContext }) => {

  const { data } = pageContext;
  const [items, setItems] = React.useState([])
  const [watchList, setWatchList] = useState([])
  const getAllEvents = () => {
    return Parse.Cloud.run('eventList')
  }

  useEffect(() => {
    getEvent()
  }, [])

  const getEvent = async () => {
    const items = await getAllEvents();
    setItems(items)
  }
  useEffect(() => {
    const watchedCookie = Cookies.get('watched')
    setWatchList(watchedCookie?.split(',') || [])
  }, [])


  const handleClick = (item) => {
    const videoId = item?.snippet?.resourceId?.videoId;
    const watchedCookie = Cookies.get('watched') || ""
    let watched = watchedCookie.split(',')
    if (!watched.find(e => e === videoId)) {
      watched.push(videoId)
    }
    Cookies.set('watched', watched.join(','), { expires: 365 })
    navigate(`/video/${videoId}`)
  }
  return <Layout>
    <Container>

      <Row>
        {
          data?.map(item => <Col lg={4}>


            <Card
              buttonText={watchList.includes(item?.snippet?.resourceId?.videoId) ? "Watch Again" : "Watch"}
              cover={item?.snippet?.thumbnails?.medium?.url}
              title={""}
              //subtitle={item?.node?.snippet?.description}
              text={item?.snippet?.title}
              onButtonClick={() => handleClick(item)}
            /></Col>)
        }

      </Row>
    </Container>
  </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
